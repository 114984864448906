import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.withCredentials = true;

import router from '../router'

const axi = axios.create({
  //  https://api.echovalleycampout.com/
  baseURL: process.env.VUE_APP_PROXYTHIS ? 'http://localhost:3000/' : 'https://api.echovalleycampout.com/',
  crossDomain: true
});

axi.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    //  Call has recieved 401, unauthed, redirect to Login
    router.replace({ name: "Signinup" })
  }
  return error;
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    home: {
      section: ''
    },
    user: {
      data: {},
      signedin: false
    },
    products:{}
  },
  mutations: {
    UPDATE_HOME_SECTION(state, payload) {
      state.home.section = payload
    },
    UPDATE_USER_DATA(state, payload) {
      state.user.data = payload
      if (payload._id) {
        state.user.signedin = true
      } else {
        state.user.signedin = false
      }
    },
    UPDATE_PRODUCT_DATA(state, payload) {
      state.products = payload
    },
    LOGOUT(state, payload) {
      state.user.data = {}
      state.user.signedin = false
    }
  },
  actions: {
    updateHomeSection({ commit }, payload) {
      commit('UPDATE_HOME_SECTION', payload)
    },
    async getEvents({commit}) {
      return await axi.get('events/all').then(response => {
        return response.data
      }).catch(e => {
        return e.response.data
      })
    },
    async getEventByID({commit}, id) {
      return await axi.get('events/id/'+id).then(response => {
        return response.data.data
      }).catch(e => {
        return e.response.data
      })
    },
    async signin({ commit }, payload) {
      return await axi.post('users/login-register', payload).then(response => {
        commit('UPDATE_USER_DATA', response.data.payload)
        router.replace({ name: "Account" })
        return response.data
      }).catch(e => {
        if (e.response) return e.response.data

        return { errors: [`${e.message}`] }
      })
    },
    async getInsta() {
      return await axi.get('social/insta').then(response => {
        return response
      }).catch(e => {
        if (e.response) return e.response.data

        return { errors: [`${e.message}`] }
      })
    },
    async getUser({ commit }) {
      return await axi.get('users/me').then(response => {
        commit('UPDATE_USER_DATA', response.data.payload)
        return response.data.payload
      }).catch(e => {
        router.replace({ name: "Signinup" })
        return e.response.data
      })
    },
    async isAuthed({ commit }) {
      return await axi.get('users/authenticated').then(response => {
        return response.data.auth
      }).catch(e => {
        if (e.response) return e.response.data

        return { errors: [`${e.message}`] }
      })
    },
    async logout({ commit }) {
      return await axi.post('users/logout').then(response => {
        commit('LOGOUT', response.data.payload)
        return response.data
      }).catch(e => {
        if (e.response) return e.response.data

        return { errors: [`${e.message}`] }
      })
    },
    async getProductData({ commit }) {
      return await axi.get('stripe/getProducts').then(response => {
        commit('UPDATE_PRODUCT_DATA', response.data)
        return response.data
      }).catch(e => {
        return e.response.data
      })
    }
  },
  getters: {
    homeSection: state => {
      return state.home.section
    },
    userData: state => {
      return state.user.data
    },
    isSignedIn: state => {
      return state.user.signedin
    },
    productData: state => {
      return state.products
    }
  },
  modules: {
  }
})
