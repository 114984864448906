<template>
    <div class="artists-wrapper noselect">
        <div class="artists-list" :style="`width: 100%; background-image:linear-gradient(to bottom, rgba(18,42, 66, .05), rgba(18,42, 66, .45)),url(${require('../assets/profpics/' + mfeed.img)}); background-size: cover; background-position: ${mfeed.horizontal} ${mfeed.vertical};`" v-for="(mfeed, i) in artists" :key="'post' + i">
            <p>{{mfeed.name}}</p>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'Artists',
    props: {
        artists: { type: Array, required: true },
    },
    data() {
        return {
        }
    },
    methods: {
        goTo(link) {
            window.open(link, '_blank');
        }
    }
}
</script>
<style scoped>
    .artists-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 400px);
        grid-template-rows: repeat(2, 400px);
        row-gap: 20px;
        column-gap: 20px;
        justify-content: center;
        padding: 20px;
    }

    .artists-list {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;
        position: relative;
    }

    .artists-list p {
        color: white;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -90%);
        font-size: 20px;
        font-weight: bolder;
        width: 90%;
        text-align: center;
    }

    /* .artists-list:hover {
        cursor: pointer;
        box-shadow: 0 10px 9px rgba(0,0,0,0.35);
    }

    .artists-list:active {
        cursor: pointer;
        box-shadow: 0 10px 9px rgba(122, 122, 122, 0.35);
    } */

    @media only screen and (max-width: 2336px) {
        .artists-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 300px);
            grid-template-rows: repeat(2, 300px);
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 990px) {
        .artists-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 250px);
            grid-template-rows: repeat(2, 250px);
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 834px) {
        .artists-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 200px);
            grid-template-rows: repeat(2, 200px);
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 683px) {
        .artists-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 200px);
            grid-template-rows: repeat(3, 200px);
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            padding: 20px;
        }
    }
</style>