<template>
    <div>
        <div class="albums-wrapper noselect">
            <div class="albums-list"
                :style="`width: 100%; background-image:linear-gradient(to bottom, rgba(18,42, 66, .25), rgba(18,42, 66, .95)),url(${require('../assets/album/' + mfeed.img)}); background-size: cover; background-position: ${mfeed.horizontal} ${mfeed.vertical};`"
                v-for="(mfeed, i) in albums" :key="'albums' + i" @click="setActiveMedia(mfeed.media, mfeed.title)">
                <p>{{ mfeed.title }}</p>
            </div>
        </div>
        <transition name="slide">
            <div class="media-modal" v-show="activemedia.length > 0">
                <div class="hrow">
                    <div class="hh">
                        <h2>{{ medialabel }}</h2>
                    </div>
                    <div class="close" @click="activemedia = []">
                        <v-icon dark left>
                            mdi-close
                        </v-icon>
                    </div>
                </div>
                <div class="media-wrapper noselect">
                    <div class="media-list" v-for="(media, i) in activemedia" :key="'activemedia' + i"
                        @click="setZoom(media.img)">
                        <v-img height="100%" width="100%"
                            :src="`https://drive.google.com/uc?export=view&id=${media.img}`">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </div>
                </div>
            </div>
        </transition>
        <div class="imagezoom" v-show="zoomed.active">
            <div class="hrow">
                <div class="hh"></div>
                <div class="close" @click="zoomed.active = false">
                    <v-icon dark left>
                        mdi-close
                    </v-icon>
                </div>
            </div>
            <v-img height="100%" width="100%" contain :src="`https://drive.google.com/uc?export=view&id=${zoomed.img}`">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </div>
        <div class="filter" :style="`z-index: ${zoomed.active ? '9999' : '99'};`" v-show="activemedia.length > 0"></div>
    </div>
</template>
<script>
export default {
    name: 'albums',
    props: {
        albums: { type: Array, required: true },
    },
    data() {
        return {
            activemedia: [],
            medialabel: '',
            zoomed: {
                active: false,
                img: null
            }
        }
    },
    methods: {
        goTo(link) {
            window.open(link, '_blank');
        },
        setZoom(img) {
            console.log('setting zoom')
            this.zoomed = {
                active: true,
                img: img
            }
        },
        setActiveMedia(media, label) {
            this.medialabel = label
            this.activemedia = []
            this.activemedia = media
        }
    }
}
</script>
<style scoped>
.albums-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 400px);
    row-gap: 20px;
    column-gap: 100px;
    justify-content: center;
    padding: 20px;
    margin-bottom: 0px;
    z-index: 9999;
}

.media-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    /* transform: translate(-50%, -50%); */
    background-color: #000;
    z-index: 9999;
    overflow: hidden;
    height: 60%;
    width: 100%;
    border-radius: 4px;

    overflow-y: auto;
}

.imagezoom {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    z-index: 99999;
    /* overflow: hidden; */
    height: 80%;
    width: 80%;
    border-radius: 4px;
    overflow-y: auto;
}

.hrow {
    position: fixed;
    background-color: black;
    z-index: 9999;
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.hrow h2 {
    margin: 0;
    padding: 0;
}

.close {
    cursor: pointer;
    transition: all .3s ease;
}

.close:hover i {
    transition: all .3s ease;
    color: var(--primary-two);
}


.media-wrapper {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(4, 250px);
    gap: 10px;
    justify-content: center;
    padding: 20px;
    margin-bottom: 0px;
    transition: all .5s ease;
}

.filter {
    background-color: black;
    opacity: 0.6;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.media-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    aspect-ratio: 1;
    cursor: pointer;
    transition: all .5s ease;
}

.media-list:hover {
    transform: scale(1.01);
    transition: all .5s ease;
}

.media-list:active {
    transform: scale(.99);
    transition: all .5s ease;
}


.media-list img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.albums-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    aspect-ratio: 1;
}

.albums-list p {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bolder;
    width: 90%;
    text-align: center;
    filter: drop-shadow(0px 0px 5px #000);
    transition: all .5s ease;
}

.albums-list:hover {
    cursor: pointer;
    box-shadow: 0 10px 9px rgba(0, 0, 0, 0.35);
    transition: all .5s ease;
}

.albums-list:active {
    cursor: pointer;
    box-shadow: 0 10px 9px rgba(122, 122, 122, 0.35);
    transition: all .5s ease;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 60%;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

@media only screen and (max-width: 1073px) {
    .media-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 200px);
        gap: 10px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 942px) {
    .albums-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 300px);
        row-gap: 15px;
        column-gap: 100px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 892px) {
    .media-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 160px);
        gap: 10px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 740px) {
    .albums-wrapper {
        display: grid;
        grid-template-columns: repeat(1, 70%);
        row-gap: 10px;
        column-gap: 100px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 710px) {
    .media-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 160px);
        gap: 10px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }

    .imagezoom {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000;
        z-index: 99999;
        height: 50%;
        width: 80%;
        border-radius: 4px;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 529px) {
    .media-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 170px);
        gap: 10px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }
}
</style>