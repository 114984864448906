<template>
    <button :style="customStyle" @click="clickButton" class="btn noselect"><span v-if="displaytext">{{displaytext}}</span><slot></slot></button>
</template>
<script>

export default {
  name: 'Navigation',
  props: {
    displaytext: { type: String, default: () => null },
    disabledtext: { type: String, default: () => '' },
    lineheight: { type: String, default: () => '' },
    width: { type: String, default: () => '' },
    backgroundcolor: { type: String, default: () => 'none' },
    color: { type: String, default: () => '#678DE8' },
    disabled: { type: Boolean, default: () => false },
    to: { type: String, default: () => null }
  },
  components: {
  },
  methods: {
    scrollMeTo(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;

        window.scrollTo(0, top);
    },
    clickButton() {
      if (this.disabled) return

      if (this.to !== null) {
        this.goto(this.to)
      }
      this.$emit('button-clicked')
    },
    goto(name){
      if (this.$route.path == name) return
      this.$router.push({path: name})
    },
  },
  computed: {
      customStyle () {
        return {
          'line-height': this.lineheight,
          'width': this.width,
          'background-color': this.backgroundcolor,
          '--btn-color': this.color
        }
      }
  }
}
</script>
<style scoped>
    .btn {
      margin: 0 auto;
      border: 2px solid var(--btn-color);
      border-radius: 5px;
      background-image: none;
      /* padding: 0px 10px; */
      transition: all .5s ease-in-out;
      text-transform: uppercase;
      min-width: 80px;
      text-align: center;
      position: relative;
      height: 40px;
    }

    .btn:hover {
        cursor: pointer;
        color: white;
        border-color: var(--btn-color);
        background-color: var(--btn-color);
        transition: all .5s ease-in-out;
    }

    .btn:active {
        color: white;
        background-color: var(--btn-color);
        opacity: 60%;
        transition: all .2s ease-in-out;
    }

    .btn-disabled:hover {
      content: 'comming soon';
    };

</style>
