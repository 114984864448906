<template>
    <div>
        <hero src="confetti.jpg" alt="Music"
            text="Media" height="300px"></hero>

        <h2>Photo Albums</h2>
        <mediagrid :albums="albums"></mediagrid>

        <h2>Team Echo Valley</h2>
        <instascroll style="margin-bottom: 100px;"></instascroll>
    </div>
</template>
<script>
import hero from '../components/Hero.vue'
import mediagrid from '../components/MediaGrid.vue'
import instascroll from '../components/InstaScroll.vue'

export default {
    name: 'Media',
    data() {
        return {
            albums: [
                {
                    title: 'Campout 2021',
                    link: 'https://drive.google.com/drive/folders/16ih-__-F49tA9kTR7oGZnfiPaRkmDOrM?usp=sharing',
                    img: 'ev2021.jpg',
                    horizontal: 'center',
                    vertical: 'center',
                    media: [
                        {
                            img: '1dSTsChueH5cVhnYR1_w-_MSOeDdwf_Dw'
                        },
                        {
                            img: '1BRCcKvN-Qnz4lAhmBqWjOdNV_3_qzN1i'
                        },
                        {
                            img: '1gJEtyZUPALdWSD2YYfY1789M-CWTYzzv'
                        },
                        {
                            img: '1OOKI3DmefX1QGNLENx5A6jO-VdZmNa6V'
                        },
                        {
                            img: '1V7_5ETyYIxR2acHsARmJCLeuLclZ_2vU'
                        }
                    ]
                },
                {
                    title: 'Launch Stream',
                    link: 'https://drive.google.com/drive/folders/1yP7E-9mkrBeaXy4XF_7Ok2Y6LHRMrqbw?usp=sharing',
                    img: 'LiveStream1.jpg',
                    horizontal: 'center',
                    vertical: 'center',
                    media: [
                        {
                            img: '1zTSJjkhg4NEB2ewFQapwdCSwmRFOAFNH'
                        },
                        {
                            img: '1dI15UyqRb2pHNHixqizQirYoN9pf09Tq'
                        },
                        {
                            img: '1Hj_4bajiPq36G8JuFyKHRNICBlnVoXn5'
                        },
                        {
                            img: '1JkGSu1hWCgxGoR3s0G6YmN9L4uzw5bIw'
                        },
                        {
                            img: '1kB-IyAVF_dj824Zc93xfo1jwJc-CN-Uh'
                        },
                        {
                            img: '1B1wbhSXvg_6mblLT8aVpVrxwEt-kZ9GT'
                        },
                        {
                            img: '1S1koJQ1B6ikOKTjSuGIB5tcohtLBj96b'
                        },
                        {
                            img: '1QB4Rh32TH5XrA0x-1GuZz3SySnDx0hI7'
                        },
                        {
                            img: '1WVchxlELjcO4oSEzP4N969sh8HgHcqPM'
                        },
                        {
                            img: '18rdBQ5xPKgI--itvLvJc-CkLG0d87mN_'
                        },
                        {
                            img: '1qPKypxddocAYUSv3M0RJeqPVCSs9diuN'
                        },
                        {
                            img: '17vqB9AFDxmme2YthML926ayMl_ohq-6q'
                        },
                        {
                            img: '1ZoT44uYyAnEqkDnNz5f17rrxG5z9tdry'
                        },
                        {
                            img: '1aY4MRJBpWqnr7HqvRZdUSj-qSl5TPaGz'
                        },
                        {
                            img: '1_l99anvJToCPv-a79YkhIx24Xe8tnXxv'
                        },
                        {
                            img: '1Wj9kdgN3CpbzlXKPGRkStdKa2-2DIHF8'
                        },
                        {
                            img: '1Bi0WT8Ps9PRQAupqk-r22SxPLWUMBLBx'
                        }
                    ]
                },
                {
                    title: 'Renascence 2022',
                    link: 'https://drive.google.com/drive/folders/19vEg_FY5ycljKDkXCWxWIV4EfMwECC2D?usp=sharing',
                    img: 'renascence.jpg',
                    horizontal: 'center',
                    vertical: 'center',
                    media: [
                        {
                            img: '1UdUW80ZPCtucJoVXC39wuwnV2kap0PWq'
                        },
                        {
                            img: '1eT1H4Tjto7VEDy9Kb3bl7T7FJ0NqSzHu'
                        },
                        {
                            img: '1ggn0C-tdTIkg3ifllfnEYuHTHhCVa44I'
                        },
                        {
                            img: '1-3ik_eA8uxvP7Lvso1gZZQxizResdYtM'
                        }
                    ]
                }
            ]
        }
    },
    components: {
        'hero': hero,
        'mediagrid': mediagrid,
        'instascroll': instascroll
    },
    watch: {
    },
}
</script>

<style scoped>
</style>
