<template>
    <div>
        <div class="social-wrap noselect">
            <div class="left-toggle" @click="swipeLeft">
                <v-icon
                dark
                left
                >
                    mdi-chevron-left
                </v-icon>
            </div>
            <div class="social-wrap" ref="content">
                <div class="social-list" v-for="(post, i) in feed" :key="'post1' + i" @click="goTo(post.permalink)">
                    <img :src="post.media_url" />
                </div>
                <div class="social-list" v-for="(post, i) in feed" :key="'post2' + i" @click="goTo(post.permalink)">
                    <img :src="post.media_url" />
                </div>
                <div class="social-list" v-for="(post, i) in feed" :key="'post3' + i" @click="goTo(post.permalink)">
                    <img :src="post.media_url" />
                </div>
            </div>
            <div class="right-toggle" @click="swipeRight">
                <v-icon
                dark
                left
                >
                    mdi-chevron-right
                </v-icon>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'Input',
    props: {
    },
    data() {
        return {
            feed: [],
            lastpos: 0
        }
    },
    async mounted() {
        this.getFeed()
    },
    methods: {
        ...mapActions(['getInsta']),
        async getFeed() {
            let insta = await this.getInsta()
            this.feed = insta.data.data
        },
        goTo(link) {
            window.open(link, '_blank');
        },
        scrollTo(element, scrollPixels, duration) {
            const scrollPos = element.scrollLeft;
            // Condition to check if scrolling is required
            if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
                // Get the start timestamp
                const startTime =
                    "now" in window.performance
                        ? performance.now()
                        : new Date().getTime();

                function scroll(timestamp) {
                    //Calculate the timeelapsed
                    const timeElapsed = timestamp - startTime;
                    //Calculate progress 
                    const progress = Math.min(timeElapsed / duration, 1);
                    //Set the scrolleft
                    element.scrollLeft = scrollPos + scrollPixels * progress;
                    //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
                    if (timeElapsed < duration) {
                        //Request for animation
                        window.requestAnimationFrame(scroll);
                    } else {
                        return;
                    }
                }
                //Call requestAnimationFrame on scroll function first time
                window.requestAnimationFrame(scroll);
            } else if(scrollPos === this.lastpos) {
                if (scrollPos != 0)
                element.scrollLeft = 0
            }

            this.lastpos = scrollPos
        },
        swipeLeft() {
            const content = this.$refs.content;
            this.scrollTo(content, -300, 800);
        },
        swipeRight() {
            const content = this.$refs.content;
            this.scrollTo(content, 300, 800);
        }
    }
}
</script>
<style scoped>
.social-wrap {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100vw;
    height: 200px;
}

.list-wrap {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.social-list {
    display: inline-block;
    height: 200px;
    width: 200px;
    cursor: pointer;
}

.social-list img {
    object-fit: cover;
    pointer-events: none;
    height: 100%;
}

.left-toggle, .right-toggle {
    background-color: rgba(128, 0, 128, 0.336);
    z-index: 99;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.left-toggle {
    position: absolute;
    top: 50%;
    left: 10px;
    bottom: 0;
    transform: translateY(-50%);
}

.right-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    bottom: 0;
    transform: translateY(-50%);
}

.left-toggle i, .right-toggle i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.left-toggle:hover, .right-toggle:hover {
    opacity: 0.8;
    cursor: pointer;
}

.left-toggle:active, .right-toggle:active {
    opacity: 1;
    cursor: pointer;
}

</style>