<template>
  <div>
    <v-carousel style="margin-top: 00px;" cycle height="500" hide-delimiter-background show-arrows-on-hover
      class="noselect">
      <v-carousel-item dark v-for="(slide, i) in slides" :key="i" :src="require('../assets/hero/' + slide.img)" :gradient="slide.gradient || null">
        <v-layout justify-center align-center style="text-align: center; max-width: 70%; margin: 0 auto;"
          class="fill-height">
          <div>
            <h2 class="text-h2 bold pb-5">
              {{ slide.header }}
            </h2>
            <div class="text bold" style="font-size: 20px;">
              {{ slide.text }}
            </div>
             <v-btn v-if="slide.action && slide.action.text" @click="goTo(slide.action.link)" depressed color="#EC9DED" style="margin-top: 10px;">
                {{slide.action.text}}
              </v-btn>
          </div>
        </v-layout>
      </v-carousel-item>
    </v-carousel>

    <h2>Whats Going On</h2>
    <instascroll></instascroll>

    <h2>Music On Repeat</h2>
    <music :music="musicdata" style="margin-bottom: 30px;"></music>

    <h2>Events</h2>
    <events></events>

    <transition name="bouncein">
      <div class="to-top" @click="toTop" v-show="showArrow">
        <svg viewBox="0 0 24 24">
          <path fill="var(--primary-two)" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import instascroll from '../components/InstaScroll.vue'
import music from '../components/Music.vue'
import events from '../components/Events.vue'

export default {
  name: 'Home',
  data() {
    return {
      showArrow: false,
      musicdata: [
        {
          title: 'musa + jedwill = old ways [Clearcast Remix]',
          link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
          img: 'https://i1.sndcdn.com/artworks-LCOC0b8aFpl5Zbrv-bG1xrQ-t500x500.jpg',
          horizontal: 'center',
          vertical: 'center'
        },
        {
          title: 'Neumonic - Something Special',
          link: 'https://soundcloud.com/fantastic-voyage-music/neumonic-something-special?in=nickneumonic/sets/neumonic-something-special',
          img: 'https://i1.sndcdn.com/artworks-EUuMKw75CQs063Rl-idtOyg-t500x500.jpg',
          horizontal: 'center',
          vertical: 'center'
        },
        {
          title: 'MVPL - Generate',
          link: 'https://soundcloud.com/maplebass/generate',
          img: 'https://i1.sndcdn.com/artworks-rfUdDwqTJzRorvO2-IyGCqw-t500x500.jpg',
          horizontal: 'center',
          vertical: 'center'
        },
        {
          title: 'Gerastic - Need That Love',
          link: 'https://open.spotify.com/track/24zZCyf4bM7d0ErUtOy6UU?si=8b456d59d857456f&nd=1',
          img: 'https://i.scdn.co/image/ab67616d00001e022c9288774fc1c748321bfe38',
          horizontal: 'center',
          vertical: 'center'
        },
        {
          title: 'Sidepiece - Acrobatic (Sampo Bootleg)',
          link: 'https://soundcloud.com/samposounds/sidepiece-acrobatic-sampo',
          img: 'https://i1.sndcdn.com/avatars-rHspESLIaXBl8K3e-VTcIIQ-t500x500.jpg',
          horizontal: 'center',
          vertical: '30%'
        },
        {
          title: 'Bleep Bloop Ft. Gary Paintin - K9 Unit (Willow Remix)',
          link: 'https://soundcloud.com/willowedm/k9-unit-willow-remix',
          img: 'https://i1.sndcdn.com/artworks-TXPvEQlAyGVh2Nw3-m6nZgw-t500x500.jpg',
          horizontal: 'center',
          vertical: 'center'
        }
      ],
      slides: [
        {
          header: 'Campout 2022',
          text: 'August 4 - 6, 2022',
          color: 'red',
          img:'campoutbg.png',
          gradient: 'to top right, rgba(77, 11, 131, 0.0), rgba(34, 105, 175, 0.0)',
          action: {
            text: 'Get Your Ticket',
            link: 'https://www.tickettailor.com/events/echovalleyevents/678708/'
          }
        }
      ]
    }
  },
  components: {
    'instascroll': instascroll,
    'music': music,
    'events': events
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    homeSection(data) {
      if (data == '') return
      var element = this.$refs[data];
      var top = element[0].offsetTop;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  methods: {
    ...mapActions(['updateHomeSection']),
    toTop() {
      this.updateHomeSection('')
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    goTo(link) {
      window.open(link, '_blank');
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }

      this.showArrow = currentScrollPosition > 100


      this.lastScrollPosition = currentScrollPosition
    }
  },
  computed: {
    ...mapGetters(['homeSection'])
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.to-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  cursor: pointer;
  z-index: 999;
}

.to-top svg {
  width: 50px;
  height: 50px;
}

.bouncein-enter-active,
.bouncein-leave-active {
  transition: all .5s;
}

.bouncein-enter,
.bouncein-leave-to {
  transform: scale(0);
  opacity: 0;
}

@media screen and (max-width: 1250px) {
  .cardgrid {
    grid-template-columns: 300px 300px 300px; 
  }
}

@media screen and (max-width: 925px) {
  .cardgrid {
    grid-template-columns: 300px 300px;
  }
}

@media screen and (max-width: 664px) {
  .to-top {
    display: none;
  }

  .cardgrid {
    grid-template-columns: 400px;
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
</style>
