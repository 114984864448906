<template>
  <div style="height: 40vw; text-align: center; margin-top: 40px;">
    <h1>404 | Page Not Found</h1>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'fourohfour',
  data () {
    return {
    }
  }
}
</script>

<style scoped>
</style>
