<template>
    <div class="inp">
        <input :type="type == 'password' ? visible ? 'text' : 'password' : type" :style="customStyles" :name="name" :value="value" @input="$emit('input', $event.target.value)" :placeholder="placeholder" :required="required"/>
        <span v-show="visible && type=='password'" @click="visible=false" class="material-icons noselect icn" >visibility</span>
        <span v-show="!visible  && type=='password'" @click="visible=true" class="material-icons noselect icn-hide">visibility_off</span>
    </div>
</template>
<script>

export default {
    name: 'Input',
    props: {
        value: { required: true },
        placeholder: { type: String, default: () => '' },
        type: { type: String, default: () => 'text' },
        name: { type: String, default: () => '' },
        required: { type: Boolean, default: () => false },
        error: { type: Boolean, default: () => false }
    },
    data () {
        return {
            visible: false
        }
    },
    computed: {
        customStyles () {
            return {
                'border': this.error ? '2px solid #D16666' : ''
            }
        }
    }
}
</script>
<style scoped>
.inp{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.inp input{
    width: 100%;
    font-size: 25px;
    border: none;
    outline: none;
    padding: 12px;
    transition: all 0.3s ease;
    color: #fff;
    background:#000;
    background:rgba(0,0,0,0.16);
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3) inset, 0 1px rgba(255, 255, 255, 0.06);
    border: 0 none;
    border-radius: 3px;
}

.inp input:focus{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.icn, .icn-hide {
    position: absolute;
    top: 16px;
    right: 12px;
    z-index: 2;
    cursor: pointer;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    font-size: 25px;
    border: none;
    outline: none;
    padding: 12px;
    transition: all 0.3s ease;
    color: #fff;
    background:#000;
    background:rgba(0,0,0,0.16);
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3) inset, 0 1px rgba(255, 255, 255, 0.06);
    border: 0 none;
    border-radius: 3px;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3) inset, 0 1px rgba(255, 255, 255, 0.06);
    transition: background-color 5000s ease-in-out 0s;
}

</style>