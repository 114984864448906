<template>
    <div class="footer-container">
        <div class="footer">
            <!-- <div class="bubbles">
                <div v-for="(bubble, index) in bubbles" :key="'bubble'+index" class="bubble" :style="{'--size':bubble.size, '--distance':bubble.distance, '--position':bubble.position, '--time':bubble.time, '--delay':bubble.delay}"></div>
            </div> -->
            <div class="content">
                <div>
                   <v-img style="margin: 0 auto;" width="100px" alt="Echo Valley Campout" src="../../assets/logo.png"></v-img>
                </div>
                <div style="margin-top: 10px;">
                    <div>
                      <a @click="to('Privacy')">Privacy Policy</a>| <a @click="to('Terms')">Terms of Use</a>
                    </div>
                    <div>
                      <sub style="color: #858383;">By continuing on this page, you agree to our terms of use. ©2021 Echo Valley Events</sub>
                    </div>
                </div>
            </div>
        </div>
        <!-- <svg style="position:fixed; top:100vh">
            <defs>
                <filter id="blob">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="blob"></feColorMatrix>
                <feComposite in="SourceGraphic" in2="blob" operator="atop"></feComposite>
                </filter>
            </defs>
        </svg> -->
    </div>
</template>
<script>

export default {
    name: 'Footer',
    data () {
        return {
            bubbles: []
        }
    },
    mounted () {
        // let l = Math.random() * (50-20) + 20

        // if (screen.width < 650) {
        //     l = 20
        // }

        // let pos = 0;
        // for (pos; pos < l; pos++) {
        //     let size = this.genRand(2, 6) + 'rem'
        //     let distance = this.genRand(14, 16) + 'rem'
        //     let position = this.genRand(-4, 100) + '%'
        //     let time = this.genRand(2, 3) + 's'
        //     let delay = this.genRand(-5, 5) + 's'

        //     this.createBubble(size, distance, position, time, delay)
        // }
    },
    methods: {
        genRand(min, max, decimalPlaces = 20) {  
            var rand = Math.random() < 0.5 ? ((1-Math.random()) * (max-min) + min) : (Math.random() * (max-min) + min);
            var power = Math.pow(10, decimalPlaces);
            return Math.floor(rand*power) / power;
        },
        createBubble(size, distance, position, time, delay) {
            this.bubbles.push({
                size: size,
                distance: distance,
                position: position,
                time: time,
                delay: delay
            })
        },
        to(name){
            if (this.$route.name == name) return
            this.$router.push({name: name})
        }
    }
}
</script>
<style scoped>
.coolfont {
  font-family: Attack Of Monster, Helvetica, Arial, sans-serif;
}

.footer-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: "main" "footer";
  overflow-x: hidden;
  /* min-height: 400px; */
  background-color: #333232;
  overflow: hidden;
}
.footer-container .footer {
  z-index: 1;
  --footer-background: var(--primary-one);
  display: grid;
  position: relative;
  grid-area: footer;
}
.footer-container .footer .bubbles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background: var(--footer-background);
  filter: url("#blob");
}
.footer-container .footer .bubbles .bubble {
  position: absolute;
  left: var(--position, 50%);
  background-image: linear-gradient(var(--primary-one), var(--primary-two));
  border-radius: 100%;
  -webkit-animation: bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s), bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
          animation: bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s), bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  transform: translate(-50%, 100%);
}
.footer-container .footer .content {
  z-index: 2;
  text-align: center;
  padding: 2rem;
  background: #333232;
}
.footer-container .footer .content a,
.footer-container .footer .content p {
  color: #f5f7fa;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.footer-container .footer .content div a {
  color: #EC9DED;
}

.footer-container .footer .content a:hover {
  transition: all 0.3s ease-in-out;
  color: white;
  cursor: pointer;
}
.footer-container .footer .content .team:hover {
  transition: all 0.3s ease-in-out;
  color: var(--primary-one);
  cursor: pointer;
}
.footer-container .footer .content b {
  color: white;
}
.footer-container .footer .content p {
  margin: 0;
  font-size: 0.75rem;
}
.footer-container .footer .content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-container .footer .content > div > div {
  margin: 0.25rem 0;
}
.footer-container .footer .content > div > div > * {
  margin-right: 0.5rem;
}
.footer-container .footer .content > div .image {
  align-self: center;
  width: 4rem;
  height: 4rem;
  margin: 0.25rem 0;
  background-size: cover;
  background-position: center;
}

@-webkit-keyframes bubble-size {
  0%, 75% {
    width: var(--size, 4rem);
    height: var(--size, 4rem);
  }
  100% {
    width: 0rem;
    height: 0rem;
  }
}

@keyframes bubble-size {
  0%, 75% {
    width: var(--size, 4rem);
    height: var(--size, 4rem);
  }
  100% {
    width: 0rem;
    height: 0rem;
  }
}
@-webkit-keyframes bubble-move {
  0% {
    bottom: -4rem;
  }
  100% {
    bottom: var(--distance, 10rem);
  }
}
@keyframes bubble-move {
  0% {
    bottom: -4rem;
  }
  100% {
    bottom: var(--distance, 10rem);
  }
}

</style>
