<template>
    <div>
        <!-- <hero :url="eventdata.tt_metadata.event.images.header" alt="Echo Valley Campout" position="top center" filter="to bottom, rgba(18,42, 66, .10), rgba(18,42, 66, .95)" height="300px"></hero> -->
        <v-img
            :src="eventdata.tt_metadata.event.images.header">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </template>
        </v-img>
        <h2>{{eventdata.tt_metadata.event.name}} ({{getDateFormat(eventdata.tt_metadata.event.start.date, eventdata.tt_metadata.event.end.date)}})</h2>

        <v-btn  @click="goTo(eventdata.tt_metadata.event.url)" depressed color="#EC9DED" style="margin-left: 20px;">
            Buy Tickets Now!
        </v-btn>

        <div style="padding: 20px;" v-html="eventdata.tt_metadata.event.description"></div>
    </div>
</template>
<script>
import hero from '../components/Hero.vue'
import { mapActions } from 'vuex'

export default {
    name: 'Media',
    data() {
        return {
            eventid: this.$route.params.eventid,
            eventdata: {},
            monthnames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
    },
    components: {
        'hero': hero
    },
    mounted() {
        this.getEventData()
    },
    computed: {
    },
    methods: {
        ...mapActions([
            'getEventByID'
        ]),
        async getEventData() {
            this.eventdata = await this.getEventByID(this.eventid)
        },
        getDateFormat(start, end) {
            let s = new Date(start)
            let e = new Date(end)

            let month = s.getMonth()
            let sday = s.getDay()
            let eday = e.getDay()
            let year = s.getFullYear()

            return `${this.monthnames[month]} ${sday} - ${eday}, ${year}`
        },
        goTo(link) {
            window.open(link, '_blank');
        }
    },
}
</script>

<style scoped>
</style>
