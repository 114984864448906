<template>
    <div class="events-wrapper">
        <v-card dark class="events-list" v-for="(event, i) in events" :key="'events' + i">
            <v-img min-height="200px" max-height="300px" :src="event.tt_metadata.event.images.header">
            </v-img>
            <v-card-title>
                {{ event.tt_metadata.event.name }} <span style="margin-left: 10px; color: #ccc;">(21+)</span>
            </v-card-title>
            <v-card-subtitle>
                <v-list-item class="grow" style="padding: 0px !important">
                    <v-list-item-title>{{getDateFormat(event.tt_metadata.event.start.date, event.tt_metadata.event.end.date)}}</v-list-item-title>
                    <v-btn text color="#EC9DED" @click="goTo(event.tt_metadata.event.url, 'external')">
                        Buy Tickets Now
                    </v-btn>
                </v-list-item>
            </v-card-subtitle>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Events',
    data() {
        return {
            events: [],
            monthnames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
    },
    components: {
    },
    watch: {
    },
    mounted() {
        this.getAllEvents()
    },
    methods: {
        ...mapActions([
            'getEvents'
        ]),
        async getAllEvents() {
            let data = await this.getEvents()
            this.events = data.data
        },
        getDateFormat(start, end) {
            let s = new Date(start)
            let e = new Date(end)

            let month = s.getMonth()
            let sday = s.getDay()
            let eday = e.getDay()
            let year = s.getFullYear()

            return `${this.monthnames[month]} ${sday} - ${eday}, ${year}`
        },
        goTo(link, type) {
            if (type == 'page') {
                this.$router.push('/event/'+event._id)
            } else {
                window.open(link, '_blank');
            }
        }
    }
}
</script>

<style scoped>
.events-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(1, 275px); */
    gap: 20px;
    justify-content: center;
    padding: 20px;
    margin-bottom: 0px;
}


.events-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
}

@media only screen and (max-width: 1305px) {
    .events-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 872px) {
    .events-wrapper {
        grid-template-columns: repeat(1, 90vw);
    }
}
</style>
