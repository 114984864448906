<template>
    <div>
        <hero src="triangle.jpg" alt="Music"
            text="Our Favorite Music" height="300px" subtext=""></hero>

        <h2>Our Favorite Artists</h2>
        <artists :artists="artistdata"></artists>

        <h2>Echo Valley Radio</h2>
        <music :music="musicdata" style="margin-bottom: 30px;"></music>
    </div>
</template>
<script>
import music from '../components/Music.vue'
import hero from '../components/Hero.vue'
import artists from '../components/Artists.vue'

export default {
    name: 'Home',
    data() {
        return {
            artistdata: [
                {
                    name: 'GERASTIC',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'gerastic.png',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    name: 'MVPL',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'mvpl.png',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    name: 'CLEARCAST',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'clearcast.png',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    name: 'MORI.',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'mori.png',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    name: 'LAPOSITIVE',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'lapostive.png',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    name: 'SAMPO',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'sampo.png',
                    horizontal: 'center',
                    vertical: 'center'
                }
            ],
            musicdata: [
                {
                    title: 'musa + jedwill = old ways [Clearcast Remix]',
                    link: 'https://soundcloud.com/clear_cast/musa-jedwill-old-ways-clearcast-remix',
                    img: 'https://i1.sndcdn.com/artworks-LCOC0b8aFpl5Zbrv-bG1xrQ-t500x500.jpg',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    title: 'Neumonic - Something Special',
                    link: 'https://soundcloud.com/fantastic-voyage-music/neumonic-something-special?in=nickneumonic/sets/neumonic-something-special',
                    img: 'https://i1.sndcdn.com/artworks-EUuMKw75CQs063Rl-idtOyg-t500x500.jpg',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    title: 'MVPL - Generate',
                    link: 'https://soundcloud.com/maplebass/generate',
                    img: 'https://i1.sndcdn.com/artworks-rfUdDwqTJzRorvO2-IyGCqw-t500x500.jpg',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    title: 'Gerastic - Need That Love',
                    link: 'https://open.spotify.com/track/24zZCyf4bM7d0ErUtOy6UU?si=8b456d59d857456f&nd=1',
                    img: 'https://i.scdn.co/image/ab67616d00001e022c9288774fc1c748321bfe38',
                    horizontal: 'center',
                    vertical: 'center'
                },
                {
                    title: 'Sidepiece - Acrobatic (Sampo Bootleg)',
                    link: 'https://soundcloud.com/samposounds/sidepiece-acrobatic-sampo',
                    img: 'https://i1.sndcdn.com/avatars-rHspESLIaXBl8K3e-VTcIIQ-t500x500.jpg',
                    horizontal: 'center',
                    vertical: '30%'
                },
                {
                    title: 'Bleep Bloop Ft. Gary Paintin - K9 Unit (Willow Remix)',
                    link: 'https://soundcloud.com/willowedm/k9-unit-willow-remix',
                    img: 'https://i1.sndcdn.com/artworks-TXPvEQlAyGVh2Nw3-m6nZgw-t500x500.jpg',
                    horizontal: 'center',
                    vertical: 'center'
                }
            ]
        }
    },
    components: {
        'music': music,
        'hero': hero,
        'artists': artists
    },
    watch: {
    }
}
</script>

<style scoped>
</style>
