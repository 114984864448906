import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Music from '../views/Music.vue'
import Media from '../views/Media.vue'

import Terms from '../views/Terms.vue'
import Privacy from '../views/PrivacyPolicy.vue'

import Event from '../views/Event.vue'

// import Tickets from '../views/Tickets.vue'

import Signinup from '../views/Signinup.vue'
// import Account from '../views/Account.vue'
import Fourohfour from '../views/Fourohfour.vue'

import _ from 'lodash'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/music',
    name: 'Music',
    component: Music
  },
  {
    path: '/media',
    name: 'Media',
    component: Media
  },
  // {
  //   path: '/account',
  //   name: 'Account',
  //   component: Account
  // },
  {
    path: '/login-register',
    name: 'Signinup',
    component: Signinup
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/event/:eventid',
    name: 'Event',
    component: Event
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Fourohfour
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)

  // Put the NAME of the route you want to be guarded
  // let guard = ['Account']

  // let isAuthed = await Store.dispatch('isAuthed')
  // let isGuarded = _.findIndex(guard, (rt => {
  //   return rt == to.name
  // })) >= 0

  // if (isAuthed) {
  //   if (to.name == 'Signinup') {
  //     next({name: 'Account'})
  //   }
  // }
  
  // if (isGuarded) {
  //   if (isAuthed) {
  //     next()
  //     return
  //   } else {
  //     // If not authed, redirect user to the login page.
  //     next({name: 'Signinup'})
  //     return
  //   }
  // }

  next()
  return
})

export default router
