<template>
    <div>
        <v-app-bar
        color="black"
        dark
        app
        hide-on-scroll
        >
            <v-app-bar-nav-icon class="mobile-only" @click="showSidebar = true"></v-app-bar-nav-icon>

            <v-toolbar-title style="padding-left: 0px;">
                <v-img @click="to('/')" style="cursor:pointer;" width="100px" alt="Echo Valley Campout" src="../../assets/logo.png"></v-img>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <router-link to="/account" v-if="authed" class="nav-item desktop-only">
                Account
            </router-link> -->
            
            <div class="nav-item desktop-only" v-for="(value, index) in content" :key="value.section_name + index" @click="to(value.path)">
                {{value.section_name}}
            </div>
            
            <!-- <c-button class="cbtn" v-if="authed" :disabled="true" @button-clicked="goToTickets" :displaytext="'tickets'"></c-button>
            <c-button class="cbtn" v-else @button-clicked="toLogin()" :displaytext="'Login'"></c-button> -->
        </v-app-bar>
        <v-navigation-drawer
            class="mobile-only"
            v-model="showSidebar"
            absolute
            fixed
            temporary
        >
            <v-list
                nav
                dense
            >
                <v-list-item-group
                    v-model="group"
                    active-class="active"
                >
                    <v-list-item to="/account" v-show="authed">
                        <v-list-item-title v-if="authed" >{{value.section_name}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="(value, index) in content" :to="value.path" :key="'mobile' + value.section_name + index">
                        <v-list-item-title>
                            {{value.section_name}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import CButton from '../Button.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Navigation',
    data () {
        return {
            group: null,
            showSidebar: false,
            showNavbar: true,
            lastScrollPosition: 0,
            authed: false,
            content: [
                {
                    section_name: 'About',
                    path: '/about'
                },
                {
                    section_name: 'Music',
                    path: '/music'
                },
                {
                    section_name: 'Media',
                    path: '/Media'
                },
                // {
                //     section_name: 'Events',
                //     path: '/Events'
                // }
            ]
        }
    },
    mounted () {
        window.addEventListener('scroll', this.onScroll)
        // this.isAuthed().then(data => {
        //     this.authed = data
        // })

    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    },
    components: {
        CButton
    },
    watch: {
        userData (data) {
            // this.isAuthed().then(data => {
            //     this.authed = data
            // })
        }
    },
    computed: {
        ...mapGetters(['userData'])
    },
    methods: {
        ...mapActions(['updateHomeSection', 'isAuthed']),
        toLogin() {
            this.showSidebar = false
            this.$router.push({name: 'Signinup'}).catch()
        },
        scrollMeTo(refName) {
            this.showSidebar = false

            if (this.$route.name == 'Home') {
                this.updateHomeSection(refName)
            } else {
                // Route to full page equivalent, or route back to home
                let that = this
                this.$router.push({name: "Home"}).then(() => {
                    that.updateHomeSection(refName)
                })
            }
        },
        onScroll () {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }

            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                return
            }

            this.showNavbar = (this.lastScrollPosition > currentScrollPosition) || currentScrollPosition < 200
            if (this.showNavbar) {
                this.updateHomeSection('')
            }
            
            this.lastScrollPosition = currentScrollPosition
        },
        to(name){
            this.showSidebar = false
            if (this.$route.path == name) return
            this.$router.push({path: name})
        },
        goToTickets () {
            console.log("GOING TO GET SOME TICKETSSSSSSS!")
        }
    }
}
</script>
<style scoped>
    .nbar {
        background-color: #333232;
    }

    .mobile-only {
        display: none;
    }
    .desktop-only {
        display: block;
    }
    
    .router-link-active, .active {
        color: #EC9DED !important;
        transition: all .4s ease-in-out;
    }

    .cbtn {
        margin-left: 20px;
        height: 50px;
    }

    .nav-item {
        line-height: 50px;
        margin: 0;
        text-transform: uppercase;
        vertical-align: middle;
        transition: all .4s ease-in-out;
        color: #fff;
        text-decoration: none;
        margin-left: 20px;
    }

    .nav-item:hover {
        cursor: pointer;
        color: #EC9DED;
        transition: all .4s ease-in-out;
        font-size: 16px;
    }

    .nav-item:active {
        opacity: 70%;
        transition: all .2s ease-in-out;
    }

    @media screen and (max-width: 734px) {
        .mobile-only {
            display: block;
        }
        .desktop-only {
            display: none;
        }
    }

</style>
