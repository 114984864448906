
<template>
    <div class="hero-container" :style="{ height: height }">
        <div class="filter" :style="`background-image: linear-gradient(${filter});`"></div>
        <div class="content">
            <div class="text-content">
                <div class="text-main noselect">{{ text }}</div>
                <a class="text-sub noselect" :class="`${cta ? 'text-a' : ''}`">{{ subtext }}</a>
            </div>
        </div>
        <img :src="url ? url : require('../assets/hero/' + src)" :style="`object-position: ${position}; object-fit: ${fit};`" :alt="alt" />
    </div>
</template>
<script>

export default {
    props: {
        src: { required: true },
        url: { type: String, default: () => null },
        alt: { type: String, default: () => 'Hero image' },
        text: { type: String, default: () => '' },
        subtext: { type: String, default: () => '' },
        cta: { type: String, default: () => '' },
        height: { type: String, default: () => '500px' },
        position: {type: String, default: () => 'center center'},
        filter: {type: String, default: () => 'to bottom, rgba(18, 42, 66, .25), rgba(18, 42, 66, .85)'},
        fit: {type: String, default: () => 'cover'}
    },
    components: {
    },
    methods: {
    },
    computed: {

    }
}
</script>
<style scoped>
.hero-container {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
}

.hero-container img {
    width: 100%;
    height: 110%;
    object-fit: cover;
    object-position: 50% 90%;
}

.content {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    z-index: 4;
    color: white;
    font-weight: bolder;
}

.text-content {
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;
}

.text-main {
    font-size: 40px;
    margin-bottom: 32px;
}

.text-sub {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: bolder;
    margin-top: 20px;
    font-size: 20px;
}



.filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .4;
    z-index: 2;
}

a {
    color: inherit;
    text-decoration: none;
}


.text-a {
    position: absolute;
    text-align: center;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
    cursor: pointer;
    transition: all .3s ease-in-out;
    -webkit-tap-highlight-color: transparent;
    /* for removing the highlight */

}

.text-a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--primary-one);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.text-a:hover:after {
    width: 100%;
    left: 0;
}

@media screen and (max-width: 664px) {}
</style>
