<template>
    <div class="music-wrapper noselect">
        <div class="music-list" :style="`width: 100%; background-image:linear-gradient(to bottom, rgba(18,42, 66, .25), rgba(18,42, 66, .95)),url(${mfeed.img}); background-size: cover; background-position: ${mfeed.horizontal} ${mfeed.vertical};`" v-for="(mfeed, i) in music" :key="'music' + i" @click="goTo(mfeed.link)">
            <p>{{mfeed.title}}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Music',
    props: {
        music: { type: Array, required: true },
    },
    data() {
        return {
        }
    },
    methods: {
        goTo(link) {
            window.open(link, '_blank');
        }
    }
}
</script>
<style scoped>
    .music-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 700px);
        grid-template-rows: repeat(3, 300px);
        row-gap: 20px;
        column-gap: 80px;
        justify-content: center;
        padding: 20px;
        margin-bottom: 0px;
    }

    .music-list {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        transition: all .5s ease;
    }

    .music-list p {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: bolder;
        width: 90%;
        text-align: center;
        filter: drop-shadow(0px 0px 5px #000);
    }

    .music-list:hover {
        cursor: pointer;
        transition: all .5s ease;
        box-shadow: 0 10px 9px rgba(0,0,0,0.35);
    }

    .music-list:active {
        cursor: pointer;
        transition: all .5s ease;
        transform: scale(.99);
        box-shadow: 0 10px 9px rgba(122, 122, 122, 0.35);
    }

    @media only screen and (max-width: 2336px) {
        .music-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 500px);
            grid-template-rows: repeat(3, 200px);
            row-gap: 20px;
            column-gap: 200px;
            justify-content: center;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 1235px) {
        .music-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 400px);
            grid-template-rows: repeat(3, 180px);
            row-gap: 20px;
            column-gap: 80px;
            justify-content: center;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 930px) {
        .music-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 400px);
            grid-template-rows: repeat(3, 180px);
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 872px) {
        .music-wrapper {
            display: grid;
            grid-template-columns: repeat(1, 90%);
            grid-template-rows: repeat(6, 220px);
            row-gap: 20px;
            column-gap: 20px;
            justify-content: center;
            padding: 20px;
        }
    }
</style>