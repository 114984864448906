<template>
	<div>
		<Hero src="party-people.jpg" height="300px" text="Who We Are"></Hero>
    <br>
		<div class="container">
			<div class="child-left">
				<div class="text-container">
					<div class="text-title">Listen. Love. Rave.</div>
					<div class="text-content">
						One must <b>Listen</b> to the sounds we play <br />
						One must <b>Love</b> the lights that sway <br />
						One must <b>Rave</b> the night away <br />
						These are the three simple rules we live by.
					</div>
				</div>
			</div>
			<div class="child-right">
				<img src="../assets/album/LiveStream1.jpg" width="425px" />
			</div>
		</div>
		<br />
		<div class="container">
			<div class="child-left">
				<img src="../assets/hero/hero.jpeg" width="425px" />
			</div>
			<div class="child-right">
				<div class="text-container">
					<div class="text-title">For the love of Music</div>
					<div class="text-content">
						A group of friends bonded over their love of technology, sound,
						videography and of course... <b>MUSIC!</b> <br />
						<br />Echo Valley Events is a group of friends who came together to
						throw private music get togethers and festivals.
					</div>
				</div>
			</div>
		</div>
    <br>
		<div class="container">
			<div class="text-title">Meet The Team</div>
		</div>
		<div class="container flex-gap">
			<v-card v-for="(member, index) in team" :key="index" dark width="300px">
				<v-img
					:src="require('../assets/profpics/' + member.img)"
					height="200px"
				></v-img>
				<v-card-title> {{member.name}} </v-card-title>
				<v-card-subtitle> {{member.title}} </v-card-subtitle>
			</v-card>
		</div>
	</div>
</template>

<script>
// Components
import Hero from '../components/Hero.vue'

export default {
	name: 'Home',
	data() {
		return {
			team: [
				{
					name: 'Shane',
					img: 'gerastic.png',
					title: 'Owner | Organizer'
				},
				{
					name: 'Andrew',
					img: 'andrew.png',
					title: 'Head of Media and Digital'
				},
				{
					name: 'Brandon',
					img: 'brandon.png',
					title: 'Head of Operations'
				},
				{
					name: 'Ryan',
					img: 'ryan.png',
					title: 'Head of Experience'
				},
				{
					name: 'BassKitten',
					img: 'basskitten.png',
					title: 'Head of Marketing'
				},
				{
					name: 'Jordan',
					img: 'lapostive.png',
					title: 'Head of Talent | Sound Engineer'
				},
			]
		}
	},
	components: {
		Hero
	},
	mounted() {
	},
	beforeDestroy() {
	},
	watch: {
	},
	methods: {

	},
	computed: {
	}
}
</script>

<style scoped>
.container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}
img {
  border-radius: 25px;
}

.child-left {
	flex-basis: 400px;
}

.child-right {
	flex-basis: 400px;
}


.text-title {
	font-weight: 700;
	font-size: 40px;
	line-height: 66px;
	text-align: center;
}

.text-content {
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	text-align: center;
	max-width: 375px;
}

.team-title {
	font-weight: 400;
	font-size: 15px;
	line-height: 32px;
	text-align: center;
	max-width: 375px;
}

.flex-gap > div {
  margin: 15px;
}
</style>
