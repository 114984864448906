<template>
  <div class="siu-container">
    <form class="siu-form">
      <h1>Login/Sign Up</h1>
      <div class="inpt"><ev-input :error="checkError('email')" type="text" name="email" v-model="user.email" placeholder="Email" required/></div>
      <div class="inpt"><ev-input :error="checkError('password')" type="password" name="password" v-model="user.password" placeholder="Password" required/></div>
      <transition name="fadefast">
        <div v-if="errors" class="err">
          <div class="ert">Please correct the following error(s):</div>
          <div class="ero error" v-for="(error, i) in errors" :key="i">{{ error[0] }}</div>
        </div>
      </transition>
      <ev-button @button-clicked="checkForm">Sign In</ev-button>
      <div class="extra noselect"><span class="alt">Don't have an account?</span> Just sign in above and we'll walk you through the rest!</div>
    </form>
  </div>
</template>

<script>
import validate from 'validate.js'
import EVInput from '../components/Input.vue'
import Button from '../components/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'SigninUp',
  data () {
    return {
      errors: null,
      constraints:{
        email: {
          presence: true,
          email: true
        },
        password: {
          presence: true,
          length: {
            minimum: 6,
            message: "must be at least 6 characters"
          }
        }
      },
      user: { 
        email: null,
        password: null
      }
    }
  },
  async mounted () {

  },
  watch: {
    'user.email': function (data) {
      this.errors = null
    },
    'user.password': function (data) {
      this.errors = null
    }
  },
  components: {
    'ev-input': EVInput,
    'ev-button': Button
  },
  methods: {
    ...mapActions(['signin', 'isAuthed']),
    checkError(value) {
      return this.errors && this.errors[value] && this.errors[value].length > 0
    },
    async checkForm () {
      this.errors = null
      this.errors = validate(this.user, this.constraints)

      if (!this.checkError('email') && !this.checkError('password')) {
        let response = await this.signin(this.user)
        if (response.errors) {
          let err = []

          err.push(response.errors)
          this.errors = {
            email: err
          }
          return
        }
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .siu-container {
    width: 50%;
    min-width: 250px;
    margin: 0 auto;
    text-align: center;
    background-color: #333232;
    padding: 20px 40px;
    border-radius: 6px;
    margin-top:60px;
  }

  .inpt {
    margin: 10px 0px;
  }

  .extra {
    margin-top: 20px;
    font-size: 12px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: bold;
  }

  .alt {
    color: var(--primary-two);
    font-family: Attack Of Monster, Helvetica, Arial, sans-serif;
  }

  .ert {
    padding-bottom: 10px;
    font-family: Attack Of Monster, Helvetica, Arial, sans-serif;
  }

  .ero {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: left;
  }

  .err {
    width: 300px;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .error {
    color: #D16666;
  }


  @media screen and (max-width: 664px) {
   .ert {
      width: 90%;
      text-align: left;
    }
    .siu-container {
      width: 95%;
    }
  }

  .fadefast-enter-active, .fadefast-leave-active {
      transition: all .2s;
  }

  .fadefast-enter, .fadefast-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
  }

</style>
