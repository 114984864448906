<template>
  <v-app>
    <Navigation></Navigation>
    <v-main>
      <router-view/>
    </v-main>
    <cfooter></cfooter>
  </v-app>
</template>

<script>
import Navigation from './components/structure/Navigation.vue'
import Footer from './components/structure/Footer.vue'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  metaInfo: {
    title: 'Welcome!',
    titleTemplate: '%s | Echo Valley Events',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Listen. Love. Rave.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'og:title', content: 'Echo Valley Events'},
      { name: 'og:description', content: 'Listen. Love. Rave.'},
      { name: 'og:type', content: 'website'},
      { name: 'og:url', content: 'https://echovalleyevents.com'},
      { name: 'og:image', content: 'https://uploads.tickettailor.com/c_crop,dpr_1.0,h_344,q_100,w_1080,x_0,y_151/c_scale,g_center,h_326,q_85,w_1024/v1/production/userfiles/kddco4afn48tdr7lgyn5.jpg'},
      { name: 'og:image:type', content: 'image/jpeg'},
      { name: 'twitter:card', content: 'summary_large_image'}
    ]
  },
  components: {
    Navigation, cfooter: Footer
  },
  mounted () {
    // Check if user is authenticated, and then store data if available
    // this.isAuthed().then(data => {
    //   if (data) {
    //     this.getUser()
    //   }
    // })
  },
  data () { 
    return {
      content: [
        {
          section_name: 'About'
        },
        {
          section_name: 'Media'
        },
        {
          section_name: 'Rules'
        },
        {
          section_name: 'Artists'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getUser', 'isAuthed']),
  }
}
</script>

<style>

:root {
  --primary-one: #EC9DED;
  --primary-two: #678DE8;
}

@font-face {
  font-family: "Attack Of Monster";
  src: url("./assets/AOM.ttf");
}

#app, html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(31, 29, 29); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(43, 40, 40);
  transition: all 1s ease-in-out;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(var(--primary-one), var(--primary-two));
  transition: all 1s ease-in-out;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h2 {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 8px;
}
</style>
